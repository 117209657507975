import $ from "jquery";
import anime from 'animejs/lib/anime.es.js';
// import emailjs from 'emailjs-com';
// import Rellax from 'rellax';

if (module.hot) {
  module.hot.accept();
}

(function() {

        // Get the header
        var header = document.getElementById("myHeader");
        
        // Get the offset position of the navbar
        var sticky = header.offsetTop;

        function sizeWindow(){
            var BabaProjet = document.getElementsByName("BabaTechno");
            if(window.screen.width > 1000 && BabaProjet.length > 0){
                BabaProjet[0].style.left = 120;
            }
        }

        window.onresize = function(){
            sizeWindow();
        }

        window.onload = function(){
            sizeWindow();
            Sticky();
        }

        // When the user scrolls the page, execute myFunction 
        window.onscroll = function(){
            //scrollFunction();
            Sticky();
            //Opacity();
        }

        // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
        function Sticky() {
            if (window.pageYOffset > sticky) {
                header.classList.add("headerSticky");
                header.classList.remove("light-animation");
            } else {
                header.classList.remove("headerSticky");
                header.classList.add("light-animation");
            }
        }

        // Animate to section when nav is clicked
        $('header a').on("click",(function(e) {
            if(this.getAttribute("href").endsWith(".html")){
                window.location = this.getAttribute("href");
            }
            // Treat as normal link if no-scroll class
            if ($(this).hasClass('no-scroll')) return;

            e.preventDefault();
            let heading = $(this).attr('href');
            let scrollDistance = $(heading).offset().top;
            scrollDistance = (this.innerText =="Présentation")? scrollDistance : scrollDistance - 60;
            let time = Math.abs(window.pageYOffset - $(heading).offset().top) / 2 
            $('html, body').animate({
                scrollTop: scrollDistance + 'px'
            }, time);

            // Hide the menu once clicked if mobile
            if ($('header').hasClass('active')) {
                $('header, body').removeClass('active');
            }
        }));

        // Scroll to top
        $('#to-top').on("click", (function() {
            $('html, body').animate({
                scrollTop: 0
            }, 500);
        }));

        // Scroll to first element
        $('#lead-down span').on("click", (function() {
            var scrollDistance = $('#lead').next().offset().top;
            $('html, body').animate({
                scrollTop: scrollDistance + 'px'
            }, 200);
        }));

        // Create timeline
        $('#experience-timeline').each(function() {

            let $this = $(this); // Store reference to this
            let $userContent = $this.children('div'); // user content

            // Create each timeline block
            $userContent.each(function() {
                $(this).addClass('vtimeline-content').wrap('<div class="vtimeline-point"><div class="vtimeline-block"></div></div>');
            });

            // Add icons to each block
            $this.find('.vtimeline-point').each(function() {
                $(this).prepend('<div class="vtimeline-icon"><i class="fa fa-map-marker"></i></div>');
            });

            // Add dates to the timeline if exists
            $this.find('.vtimeline-content').each(function() {
                var date = $(this).data('date');
                if (date) { // Prepend if exists
                    $(this).parent().prepend('<span class="vtimeline-date">'+date+'</span>');
                }
            });

        });

        // Open mobile menu
        $('#mobile-menu-open').on("click", (function() {
            $('header, body').addClass('active');
        }));

        // Close mobile menu
        $('#mobile-menu-close').on("click", (function() {
            $('header, body').removeClass('active');
        }));

        // Load additional projects
        $('#view-more-projects').on("click", (function(e){
            e.preventDefault();
            $(this).fadeOut(300, function() {
                $('#more-projects').fadeIn(300);
            });
        }));

        function isElementInViewport(el, inCB, outCB, rootMargin) {
            var margin = rootMargin || '-10%';
            function handleIntersect(entries, observer) {
                var entry = entries[0];
                if (entry.isIntersecting) {
                    if (inCB && typeof inCB === 'function') inCB(el, entry);
                } else {
                    if (outCB && typeof outCB === 'function') outCB(el, entry);
                }
            }
            var observer = new IntersectionObserver(handleIntersect, {rootMargin: margin});
            observer.observe(el);
        }

        $('#sphere').each(function() {

            var sphereEl = document.querySelector('.sphere-animation');
            var spherePathEls = sphereEl.querySelectorAll('.sphere path');
            var pathLength = spherePathEls.length;
            var introPlayed = true;
            var aimations = [];

            var breathAnimation = anime({
                begin: function() {
                    for (var i = 0; i < pathLength; i++) {
                        aimations.push(anime({
                            targets: spherePathEls[i],
                            stroke: {value: ['rgba(255,75,75,1)', 'rgba(80,80,80,.35)'], duration: 500},
                            translateX: [2, -4],
                            translateY: [2, -4],
                            easing: 'easeOutQuad',
                            autoplay: false
                        }));
                    }
                },
                update: function(ins) {
                    aimations.forEach(function(animation, i) {
                        var percent = (1 - Math.sin((i * .35) + (.0022 * ins.currentTime))) / 2;
                        animation.seek(animation.duration * percent);
                    });
                },
                duration: Infinity,
                autoplay: false
            });
            var introAnimation = anime.timeline({
                autoplay: true
            })
                .add({
                    targets: sphereEl,
                    opacity: [.001, 1],
                    translateY: [60, 0],
                    duration: 2000,
                    easing: 'easeOutSine',
                }, 0)
                .add({
                    targets: spherePathEls,
                    strokeDashoffset: {
                        value: [anime.setDashoffset, 0],
                        duration: 3900,
                        easing: 'easeInOutCirc',
                        delay: anime.stagger(190, {direction: 'reverse'})
                    },
                    duration: 2000,
                    delay: anime.stagger(60, {direction: 'reverse'}),
                    easing: 'linear'
                }, 0);

            var shadowAnimation = anime({
                targets: '#sphereGradient',
                x1: '25%',
                x2: '25%',
                y1: '0%',
                y2: '75%',
                duration: 30000,
                easing: 'easeOutQuint',
                autoplay: false
            }, 0);

            function play() {
                if (!introPlayed) {
                    introAnimation.play();
                    introPlayed = true;
                }
                breathAnimation.play();
                shadowAnimation.play();
            }
            function pause() {
                breathAnimation.pause();
                shadowAnimation.pause();
            }
            isElementInViewport(sphereEl, play, pause);
        });
})()
